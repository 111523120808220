<!--
  作者：顾先生
-->
<template>
  <div>
    <div class="wapper chengXinContent">
      <title1 title="关于诚信企业计划"></title1>
      <p class="chengXin_p1">1、什么是“诚信企业计划”</p>
      <p class="chengXin_p2">“诚信企业计划”是由第三方信用公司（河北鲸诚信用服务有限公司）发起，主旨是让一直诚信经营的企业更好的展现和展示出企业信用形象，让诚信经营的理念可视化、展现化，从而降低中小企业的经营成本，获取更多资源和机会。</p>
      <p class="chengXin_p1">2、加入“诚信企业计划”的作用</p>
      <p class="chengXin_p2">1)让企业诚信更容易被看见，可获取“诚信企业计划”实体荣誉牌匾，让上门客户通过悬挂的金属牌匾或放于荣誉墙或办公桌的木质牌匾，更容易的了解企业的诚信；<br>
        2)让诚信经营理念更容易被查到，可获得诚信企业计划官网公示服务，可轻松查询到企业加入时间、诚信的类型等；<br>
        3)让诚信经营理念更容易被分享，为企业生成电子版荣誉证书，方便企业随时随地快速分享企业诚信经营想法，也方便扫码查看证书的有效性。<br>
        4）企业信用信息的检测与维护。</p>
      <p class="chengXin_p1">3、“诚信企业计划”收费标准（实际价格以企业申请加入时收费金额为准）</p>
      <p class="chengXin_p2">“诚信企业计划”按照经营主体申请的荣誉牌匾材质进行区别收费，费用主要用于：<br>
        1)人工审核成本，需要对企业资质、企业信息信息审核；<br>
        2)实体荣誉牌匾的制作与邮寄成本;<br>
        3)企业信用信息的展示和信息维护的成本。<br>
        诚信企业计划服务按年收费。次年到期前会联系经营主体，如还需要继续办理服务，鲸诚信会对经营主体信用情况进行审核，符合标准可继续办理，继续办理会根据经营主体次年的选择类型来进行收费。如到期不再办理，诚信企业计划提供的信用服务会全部失效。</p>
      <p class="chengXin_p1">4、“诚信企业计划”加入标准</p>
      <p class="chengXin_p2">1)依法在中国大陆注册的企业并且有意愿诚信经营； <br>
        2)成立至今不存在以下行为：失信被执行人记录、被执行人记录、近两年内存在行政处罚记录、存在重大税务违法记录、重大负面舆情、等失信行为。</p>
      <div class="fontFlex219" @click="pdfBtn"><span class="font219">3)诚信企业计划标准：</span><div class="hover219"><img src="../assets/image/pdf.png" class="pdfImg"><span class="font219Hover">JCX鲸诚信-诚信企业计划标准</span></div></div>
      <p class="chengXin_p1">5、“诚信企业计划”荣誉牌匾收到时长</p>
      <p class="chengXin_p2">加入诚信企业计划后，会对经营主体进行企业资质、企业信用信息的审核，一般耗时1至2个工作日，如果审核出现问题，我们会全额退款（约1至3个工作日内退回到原付款账号），审核通过后，会进行下一步的制作，整个周期约8个工作日，制作完成后安排EMS进行邮寄，并会短信通知经营主体牌匾的邮寄单号。</p>
      <p class="chengXin_p1">6、加入“诚信企业计划”企业的检测和处理规范</p>
      <p class="chengXin_p2">加入诚信企业计划的企业如果被检测到违背准入条件信息或行为，诚信企业计划将中止或终止服务，具体规范如下：<br>
        1）异常经营、用户投诉、吊销、重大舆情等行为，将中止服务，并在牌匾二维码扫码页面显示中止状态及情况说明。如在服务期限内企业处理完结以上行为，并重新达到诚信企业计划准入条件时，诚信企业计划将恢复服务，并撤销牌匾二维码扫码页面取消中止状态；<br>
        2）失信被执行人、重大税务违法记录、等行为，将直接终止服务，不再恢复并在牌匾二维码扫码页面显示终止状态及情况说明。<br>
      </p>
    </div>
    <PublicBottom></PublicBottom>
  </div>
</template>

<script>
import title1 from '@/components/title1.vue'
import PublicBottom from '@/components/publicBottom.vue'
export default {
  name: 'chengXinContent',
  // 注册组件
  components: { PublicBottom, title1 },
  // 注册方法
  methods: {
    pdfBtn() {
      window.open('https://jingchengxinyong.cn/jcx.pdf', '_blank')
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {
  }
}
</script>

<style scoped lang="less">
.fontFlex219{
  display: flex;
  align-items: center;
  .hover219{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .pdfImg{
    width: 20px;
    margin-right: 5px;
  }
  .font219Hover{
    transition: all .3s;
    &:hover{
      color: #e23743;
      text-decoration: underline;
    }
  }
}
.font219{
  cursor: pointer;
  transition: all .3s;
  //&:hover{
  //  text-decoration: underline;
  //  color: #d84942;
  //}
}
.chengXinContent{
  margin: 0 auto;
  .chengXin_p1{
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .chengXin_p2{
    text-align: justify;
    margin-top: 20px;
    font-size: 16px;
    line-height: 30px;
  }
}
</style>
